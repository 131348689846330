import React from "react"
import styled from "styled-components"
import { SectionCore } from "../../ts/interfaces"

export const Subline = styled.div`
  width: 4rem;
  height: 3px;
  background: var(--color-primary);
`

export const Dividor = styled.span<{
  color?: string
  height?: string
  margin?: string
}>`
  height: ${({ height }) => (height ? height : "1px")};
  margin: ${({ margin }) => (margin ? margin : "0.7em 0")};
  background: ${({ color }) => (color ? color : "var(--color-grey-1)")};
  border-radius: 2rem;
  width: 100%;
  display: block;
`

export const SectionDividor = (props: {
  isTop: boolean
  core?: SectionCore
}) => {
  let { divider, titleAlign } = props.core || {}
  divider = divider || "none"

  const showTop = divider.startsWith("top")
  const showBottom = divider.startsWith("bottom")
  const isGradient = divider.indexOf("Gradient") > -1

  if ((props.isTop && showTop) || (!props.isTop && showBottom)) {
    return (
      <div
        style={{
          margin: props.isTop ? "1rem 0 3rem 0" : "3rem 0 1rem 0",
          display: "flex",
          justifyContent:
            titleAlign === "center"
              ? "center"
              : !titleAlign || titleAlign === "left"
              ? "start"
              : "flex-end",
        }}
      >
        <div
          style={{
            width: "5rem",
          }}
        >
          <Dividor
            height="0.4rem"
            color={isGradient ? "var(--color-gradient)" : "var(--color-purple)"}
          />
        </div>
      </div>
    )
  }

  return <></>
}
